import { Link } from 'gatsby'
import React from 'react'

const getSlug = (allItems, id, type) => {
  const itemsOfType = allItems[type].nodes

  if (itemsOfType) {
    const reference = Object.keys(itemsOfType).find(
      key => itemsOfType[key]._id === id
    )

    if (reference) {
      return `/${type}/${itemsOfType[reference].slug.current}`
    }
  }
  console.log(`There was an issue creating a slug for ${type} - ${id}`)

  return ''
}

const getSlugFromInternalLink = internalLinkObject => {
  const type = Object.keys(internalLinkObject)[0]
  const slug = internalLinkObject[type].slug.current

  return {
    internalLink: `/${type}/${slug}`,
    linkId: internalLinkObject[type]._id,
  }
}

const imageLinksHandler = (imageLink, imgElement, key = '') => {
  if (imageLink.externalLink && imageLink.externalLink.href) {
    const link = imageLink.externalLink.href

    return imageLink.externalLink.blank ? (
      <a key={key} target="_blank" rel="noopener noreferrer" href={link}>
        {imgElement}
      </a>
    ) : (
      <a href={link}>{imgElement}</a>
    )
  }

  if (imageLink.internalLink) {
    const type = Object.keys(imageLink.internalLink).filter(
      curKey => !curKey.includes('_')
    )[0]

    const { slug } = imageLink.internalLink[type]
    const id = imageLink.internalLink[type]._ref
    let link = `/${type}/`
    let returnLink = false

    if (slug) {
      returnLink = true
      link += slug.current
    } else if (id) {
      returnLink = true
      link += id
    }

    if (returnLink) {
      return (
        <Link key={key} rel="canonical" to={link}>
          {imgElement}
        </Link>
      )
    }

    return imgElement
  }

  return imgElement
}

export { getSlug, imageLinksHandler, getSlugFromInternalLink }
