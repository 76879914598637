import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import PioLinksList from '../components/lists/PioLinksList'

const PioLinksContentStyles = styled.div``

export default function Links({ data }) {
  const links = data.links.nodes

  return (
    <PioLinksContentStyles className="content-width">
      <h1 className="title">BHS Links and Resources</h1>
      <PioLinksList links={links} />
    </PioLinksContentStyles>
  )
}

export const PioLinkQuery = graphql`
  query PioLinkQuery {
    links: allSanityPioLinks {
      nodes {
        id
        type
        title
        order
        pioLinksList {
          _key
          title
          url
          internalLink: _rawInternalLink(resolveReferences: { maxDepth: 1 })
        }
      }
    }
  }
`
