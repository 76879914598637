import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { getSlugFromInternalLink } from '../../utils/handleRichTextLinks'

const SingleTypeStyles = styled.div`
  margin-top: 20px;
  /* margin-left: 20px; */

  h2 {
    text-decoration: underline;
  }
`

const CategoriesStyles = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`

const CategoryStyles = styled.div`
  margin-top: 20px;
  /* padding: 0; */

  ul {
    list-style: none;
    margin: 0;
    padding-left: 25px;

    li {
      margin-top: 5px;
    }
  }
`

const separateCategories = categories =>
  categories.reduce((obj, cat) => {
    const catType = cat.type

    if (!obj[catType]) {
      obj[catType] = []
    }

    obj[catType].push(cat)

    return obj
  }, {})

const sortCategories = categories =>
  categories.sort((a, b) => a.order - b.order)

const CategoryList = ({ name, list }) => (
  <CategoryStyles>
    <h3>{name}</h3>

    <ul>
      {list.map(link => {
        if (link.internalLink) {
          const { internalLink, linkId } = getSlugFromInternalLink(
            link.internalLink
          )

          return (
            <Link
              className="dark"
              to={internalLink}
              rel="canonical"
              key={linkId}
            >
              {link.title}
            </Link>
          )
        }
        return (
          <li key={link._key}>
            <a
              className="dark"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          </li>
        )
      })}
    </ul>
  </CategoryStyles>
)

const TypeList = ({ type, cats }) => (
  <SingleTypeStyles>
    <h2>{type}</h2>
    <CategoriesStyles>
      {cats.map(cat => (
        <CategoryList key={cat.id} name={cat.title} list={cat.pioLinksList} />
      ))}
    </CategoriesStyles>
  </SingleTypeStyles>
)

export default function PioLinksList({ links }) {
  const typeObj = separateCategories(links)
  const types = Object.keys(typeObj)

  const interestedIndex = types.indexOf('For Interested People')

  if (interestedIndex !== 0 && interestedIndex !== -1) {
    types.splice(interestedIndex, 1)
    types.splice(0, 0, 'For Interested People')
  }

  return types.map(type => {
    const sortedCats = sortCategories(typeObj[type])

    return <TypeList key={type} type={type} cats={sortedCats} />
  })
}
